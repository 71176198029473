import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
import Layout from '../../components/Layout/layout'
import { Box, makeStyles } from '@material-ui/core'
import { he } from 'date-fns/locale'

const useStyles = makeStyles((theme) => {
  let styles = {
    logo: {
      [theme.breakpoints.down("md")]: {
        width: '60%',
      },
    },
    ilustracion: {
      [theme.breakpoints.down("md")]: {
        width: '60%',
      },
      [theme.breakpoints.up("md")]: {
        height: '400px',
      },
    },
  }
  return styles;
})

const Home = () => {
  const classes = useStyles()
  const history = useHistory()
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('authData'))
    if (!user) {
      history.push('/login')
    }
  }, [])
  return <Layout>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{background: '#202E3C', padding: 40}}
    >
      <img src="./static/logo.png" className={classes.logo} />
      <img src="./static/tractor.svg" className={classes.ilustracion} style={{color: '#fff'}} />
    </Box>
  </Layout>
}

export default Home
