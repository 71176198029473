import React from 'react'
import {
  Settings,
  Commute,
  Memory,
  Map,
  Person,
  Backup,
  Description,
  Terrain,
  PanTool,
  Lock,
  RvHookup,
  SortByAlpha,
  NaturePeople,
  Eco,
  Category,
  FiberManualRecord as Punto,
  BarChart,
  Room,
  PieChart,
  Home,
  GetApp,
  AccessTime
} from '@material-ui/icons'
export const items = [
  {
    icon: <Home/>,
    to: '/home',
    label: 'Inicio',
    type: 'single',
    permiso: null
  },
  {
    icon: <Settings />,
    label: 'Administración',
    type: 'primarysub',
    subitems: [
      {
        icon: <Backup />,
        label: 'Carga de datos',
        type: 'secondarysub',
        subitems: [
          {
            icon: <Punto />,
            to: '/admin/archivos-telemetria',
            label: 'Cargar archivos de telemetría',
            type: 'single'
          }
        ]
      },
      {
        icon: <Lock />,
        label: 'Autenticación',
        type: 'secondarysub',
        subitems: [
          {
            icon: <Punto />,
            label: 'Usuarios',
            type: 'single',
            to: '/admin/usuarios',
            permiso: 'autenticacion_usuarios'
          },
          {
            icon: <Punto/>,
            label: 'Roles',
            type: 'single',
            to: '/admin/roles',
            permiso: 'autenticacion_roles'
          },
          {
            icon: <Punto />,
            label: 'Permisos',
            type: 'single',
            to: '/admin/permisos',
            permiso: 'autenticacion_permisos'
          }
        ]
      },
      {
        icon: <Commute />,
        to: '/admin/maquinaria',
        label: 'Maquinaria',
        type: 'single'
      },
      {
        icon: <RvHookup />,
        to: '/admin/tipos_maquinaria',
        label: 'Tipos de maquinaria',
        type: 'single'
      },
      {
        icon: <Memory />,
        to: '/admin/gios',
        label: 'GIOs',
        type: 'single',
        permiso: 'admin_gios'
      },
      {
        icon: <Description />,
        to: '/admin/tipos-archivos',
        label: 'Tipos de archivos de telemetría',
        type: 'single',
        permiso: 'admin_tipos_archivos'
      },
      {
        icon: <PanTool />,
        to: '/admin/operaciones',
        label: 'Operaciones (motivos de parada)',
        type: 'single',
        permiso: 'admin_operaciones'
      },
      {
        icon: <SortByAlpha />,
        to: '/admin/clasificaciones',
        label: 'Clasificaciones de operaciones',
        type: 'single',
        permiso: 'admin_clasificaciones'
      },
      {
        icon: <NaturePeople />,
        to: '/admin/areas_agricola',
        label: 'Áreas agrícolas',
        type: 'single',
        permiso: 'admin_areas_agricolas'
      },
      {
        icon: <Category />,
        to: '/admin/shapefiles',
        label: 'Shapefiles',
        type: 'single',
        permiso: 'admin_shapefiles'
      },
      {
        icon: <GetApp />,
        to: '/admin/exportaciones',
        label: 'Exportar datos',
        type: 'single',
        permiso: 'descarga_datos'
      },
    ]
  },
  {
    icon: <Map />,
    label: 'Mapas',
    type: 'primarysub',
    subitems: [
      {
        icon: <Room />,
        to: '/mapa-ubicacion',
        label: 'Mapa de ubicación en tiempo real',
        type: 'single'
      },
      {
        icon: <Map />,
        to: '/mapas-gio',
        label: 'Mapa de históricos',
        type: 'single'
      },
      {
        icon: <Terrain />,
        to: '/mapas-cosecha',
        label: 'Mapas de cosecha',
        type: 'single',
      },
      {
        icon: <Eco />,
        to: '/mapa-fertilizacion',
        label: 'Mapas de fertilización',
        type: 'single'
      }
    ]
  },
  {
    icon: <PieChart />,
    label: 'Dashboards',
    type: 'primarysub',
    subitems: [
      {
        icon: <Punto />,
        to: '/dashboard-corte',
        label: 'Corte de caña',
        type: 'single'
      },
      {
        icon: <Punto />,
        to: '/dashboard-operadores',
        label: 'Operadores',
        type: 'single'
      },
      {
        icon: <Punto />,
        to: '/dashboard-maquinaria',
        label: 'Maquinaria',
        type: 'single'
      }
    ]
  },
  {
    icon: <Description />,
    label: 'Reportes',
    type: 'single',
    to: '/reportes'
  },
  {
    icon: <Person />,
    to: '/cuenta',
    label: 'Mi cuenta',
    type: 'single'
  }
]

