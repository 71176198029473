import React, {useState, useEffect} from 'react'
import {
  Typography,
  Box,
  Button
} from '@material-ui/core'
import { Search, AccessTime } from '@material-ui/icons'
import Layout from '../../components/Layout/layout'
import { getDatosEnTiempoReal } from '../../Utils/functions'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import LoadingBackdrop from '../../components/Utils/LoadingBackdrop'
const Reportes = () => {
  const { maquinaria } = useStoreState(state => ({
    maquinaria: state.maquinaria,
  }))
  const { getMaquinaria } = useStoreActions(actions => ({
    getMaquinaria: actions.getMaquinaria,
  }))
  const [loading, setLoading] = useState(false)
  const arrayToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvData = data.map((item) => headers.map((header) => item[header]).join(','));
    return headers.join(',') + '\n' + csvData.join('\n');
  };
  const getReporteHorometros = async () => {
    try {
      setLoading(true)
      const body = await getDatosEnTiempoReal(maquinaria.map(i => i.Codigo), 'horometros')
      const csv = arrayToCSV(body)
      const data = new Blob([csv], { type: 'text/csv' })
      const csvURL = window.URL.createObjectURL(data)
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', `horometros ${dayjs().format('DD-MM-YY')}.csv`)
      tempLink.click()
    } catch (error) {
      toast.error('Error al generar el reporte de horometros')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getMaquinaria()
  }, [])
  return <Layout>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<AccessTime />}
        onClick={getReporteHorometros}
      >
        Generar reporte de horometros
      </Button>
    </Box>
    <LoadingBackdrop loading={loading} />
  </Layout>
}

export default Reportes