import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  makeStyles
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import SelectMaquinariaGIO from '../../Selects/SelectMaquinariaGIO';
import LoadingButton from '../../../components/Utils/LoadingButton';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  btnCerrar: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

const FiltrosForm = ({ onSubmit, loading, onClose }) => {
  const classes = useStyles()
  const [values, setValues] = useState({
    FechaInicia: '',
    FechaTermina: '',
    Maquinaria: []
  })
  const [errors, setErrors] = useState({})

  const validateFilters = () => {
    const errorsTmp = {}
    if (!values.FechaInicia) {
      errorsTmp['FechaInicia'] = 'La fecha de inicio es requerida'
    }
    if (!values.FechaTermina) {
      errorsTmp['FechaTermina'] = 'La fecha final es requerida'
    }
    if (values.FechaInicia && values.FechaTermina) {
      const inicia = dayjs(values.FechaInicia)
      const termina = dayjs(values.FechaTermina)
      if (inicia.isAfter(termina)) {
        errorsTmp['FechaInicia'] = 'La fecha de inicio no puede ser mayor a la fecha final'
      }
      if (inicia.diff(termina, 'days') > 7) {
        errorsTmp['FechaInicia'] = 'El rango de fechas no puede ser mayor a 7 días'
      }
    }
    setErrors(errorsTmp)
    return Object.keys(errorsTmp).length === 0
  }

  const submitFilters = () => {
    if (validateFilters()) {
      onSubmit(values)
    }
  }

  return <div className={classes}>
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h6">Filtros</Typography>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
    <TextField
      fullWidth
      label="Fecha Inicia"
      type="datetime-local"
      InputLabelProps={{shrink: true}}
      value={values.FechaInicia}
      onChange={(e) => setValues({ ...values, FechaInicia: e.target.value })}
      error={Boolean(errors.FechaInicia)}
      helperText={errors.FechaInicia}
    />
    <TextField
      fullWidth
      label="Fecha Termina"
      type="datetime-local"
      InputLabelProps={{shrink: true}}
      value={values.FechaTermina}
      onChange={(e) => setValues({ ...values, FechaTermina: e.target.value })}
      error={Boolean(errors.FechaTermina)}
      helperText={errors.FechaTermina}
    />
    <SelectMaquinariaGIO
      multiple
      label="Buscar maquinaria"
      value={values.Maquinaria}
      onChange={(ev, val) => setValues({ ...values, Maquinaria: val })}
    />
    <LoadingButton
      fullWidth
      variant="contained"
      color="primary"
      onClick={submitFilters}
      loading={loading}
      style={{marginTop: 10}}
    >
      Consultar
    </LoadingButton>
  </div>

}

export default FiltrosForm
