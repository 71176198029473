import React, { useState, useEffect } from 'react'
import {
  TextField,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid
} from '@material-ui/core'
import LoadingButton from '../Utils/LoadingButton'
import { toast } from 'react-toastify'
import GIOSelect from './GIOSelect'
import TipoMaquinariaSelect from './TipoMaquinariaSelect'
import AreaSelect from './AreaSelect'
import SelectFrente from '../Selects/SelectFrente'

const MaquinariaEdit = ({ onClose, onSubmit, values=null, title, onRefresh, items=[] }) => {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    Codigo: '',
    Marca: '',
    Modelo: '',
    Serie: '',
    TipoMaquinaria: null,
    Area: null,
    Frente: null,
    GIO: null
  })
  const [errors, setErrors] = useState({})
  const validate = () => {
    let tempErrors = {};
    if (!form.Codigo) {
      tempErrors.Codigo = 'Este campo es requerido';
    }
    if (!form.Marca) {
      tempErrors.Marca = 'Este campo es requerido';
    }
    if (!form.Modelo) {
      tempErrors.Modelo = 'Este campo es requerido';
    }
    if (!form.Serie) {
      tempErrors.Serie = 'Este campo es requerido';
    }
    if (!form.TipoMaquinaria) {
      tempErrors.TipoMaquinaria = 'Este campo es requerido';
    }
    if (!form.Area) {
      tempErrors.Area = 'Este campo es requerido';
    }
    if (form.GIO && items.find(i => i.IdGIO === form.GIO.IdGIO) && !Boolean(values)) {
      tempErrors.GIO = 'El GIO ya esta signado';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  }
  const [defaults, setDefaults] = useState({
    TipoMaquinaria: null,
    Area: null,
    Frente: null,
    GIO: null
  })
  const handleChange = (ev) => {
    setForm({
      ...form,
      [ev.target.name]: ev.target.value
    })
  }
  const submit = async () => {
    try {
      if (validate()) {
        setLoading(true)
        const body = {...form}
        body['IdGIO'] = body['GIO'] ?  body['GIO'].IdGIO : null
        body['IdTipoMaquinaria'] = body['TipoMaquinaria'] ?  body['TipoMaquinaria'].IdTipoMaquinaria : null
        body['IdArea'] = body['Area'] ?  body['Area'].IdArea : null
        body['IdFrente'] = body['Frente'] ? body['Frente'].IdFrente : null
        delete body['GIO']
        delete body['TipoMaquinaria']
        delete body['Area']
        delete body['Frente']
        await onSubmit(body)
        onRefresh()
        toast.success('Registro guardado')
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (values) {
      setDefaults({
        TipoMaquinaria: values.IdTipoMaquinaria,
        Area: values.IdArea,
        Frente: values.IdFrente,
        GIO: values.IdGIO
      })
      setForm({
        ...form,
        Codigo: values.Codigo,
        Marca: values.Marca,
        Modelo: values.Modelo,
        Serie: values.Serie
      })
    }
  }, [values])
  return <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Código"
            name="Codigo"
            error={Boolean(errors.Codigo)}
            helperText={errors.Codigo}
            value={form.Codigo}
            onChange={handleChange}
          />
          <GIOSelect
            value={form.GIO}
            onChange={handleChange}
            defaultValue={defaults.GIO}
            error={Boolean(errors.GIO)}
            helperText={errors.GIO}
            maquinaria={items}
          />
          <TipoMaquinariaSelect
            value={form.TipoMaquinaria}
            onChange={handleChange}
            defaultValue={defaults.TipoMaquinaria}
          />
          <SelectFrente
            value={form.Frente}
            onChange={handleChange}
            defaultValue={defaults.Frente}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Marca"
            name="Marca"
            error={Boolean(errors.Marca)}
            helperText={errors.Marca}
            value={form.Marca}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Modelo"
            name="Modelo"
            error={Boolean(errors.Modelo)}
            helperText={errors.Modelo}
            value={form.Modelo}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Serie"
            name="Serie"
            error={Boolean(errors.Serie)}
            helperText={errors.Serie}
            value={form.Serie}
            onChange={handleChange}
          />
          <AreaSelect
            value={form.Area}
            onChange={handleChange}
            defaultValue={defaults.Area}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancelar
      </Button>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        onClick={submit}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default MaquinariaEdit
