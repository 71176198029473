import http from "./http"
import axios from "axios"
import DefaultSettings from "../pages/Usuarios/DefaultSettings"
// Esto ya no se usa en el mapa de producto aplicado
export const generateGradient = (colores = []) => {
  if (colores.length === 0 ) {
    return '#f4f6fa'
  }
  if (colores.length === 1) {
    return colores[0]
  }
  const paradas = []
  for (let i = 0; i < colores.length; i++) {
    const porcentaje = Math.ceil(100 * (i + 1) / colores.length)
    paradas.push(`${colores[i]} ${porcentaje}%`)
  }
  return `linear-gradient(90deg,${paradas.join(',')})`
}

export const CSVToJSON = (data, delimiter = ',') => {
  const fnl = data.indexOf('\n')
  const titles = data.slice(0, fnl).split(delimiter)
  if (fnl + 1 === data.length)
    return []
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter);
      return titles.reduce(
        (obj, title, index) => ((obj[title] = values[index]), obj),
        {}
      )
    })
}
export const CSVToJSONLatLon = (data, delimiter = ',') => {
  const fnl = data.indexOf('\n')
  const titles = data.slice(0, fnl).split(delimiter)
  if (fnl + 1 === data.length)
    return []
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter);
      return titles.reduce(
        (obj, title, index) => ((obj[title] = parseFloat(values[index])), obj),
        {}
      )
    })
}

export const CSVToJSON2 = (data, delimiter = ',', removeMark=false) => {
  const fnl = data.indexOf('\n')
  if (fnl + 1 === data.length)
    return []
  const titles = data.slice(0, fnl).split(delimiter).map(i => i.replace(/\"/gi, ''))
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter).map(i => parseFloat(i.replace(/\"/gi, '')));
      return titles.reduce(
        (obj, title, index) => ((obj[title] = values[index]), obj),
        {}
      )
    })
}

export const mergeGeoJSON = (geojsonList) => {
  if (!Array.isArray(geojsonList) || geojsonList.length === 0) {
    return null
  }
  const mergedFeatures = []
  geojsonList.forEach(geojson => {
    if (geojson && geojson.features && Array.isArray(geojson.features)) {
      mergedFeatures.push(...geojson.features)
    }
  })
  const mergedGeoJSON = {
    type: "FeatureCollection",
    features: mergedFeatures
  }
  return mergedGeoJSON
}

const getLink = async (query) => {
  const data = await http.get(`/maestros/shapefile/descarga?${query}`)
  return data
}

const getGeoJSON = async (links=[]) => {
  const requests = links.map(i => axios.get(i))
  const responses = await Promise.all(requests)
  const jsons = responses.map(i => i.data)
  return mergeGeoJSON(jsons)
}

export const getShapes = async (zafra=2023, finca=null, um=[]) => {
  if (um.length) {
    const reqLinks = um.map(i => getLink(`Zafra=${zafra}&Um=${i}`))
    const resLinks = await Promise.all(reqLinks)
    return await getGeoJSON(resLinks)
  }
  if (finca) {
    const link = await getLink(`Zafra=${zafra}&Finca=${finca}`)
    return await getGeoJSON([link])
  }
  const link = await getLink(`Zafra=${zafra}`)
  return await getGeoJSON([link])
}

export const dividirArrayEnGrupos = (arr, n) => {
  var grupos = []
  for (var i = 0; i < arr.length; i += n) {
    grupos.push(arr.slice(i, i + n))
  }
  return grupos
}

export const getDatosEnTiempoReal = async (deviceList=[], tipoDatos='ubicacion') => {
  const chunks = dividirArrayEnGrupos(deviceList, 10)
  const requests = chunks.map(i => http.post('/maestros/catalogo/live', {
    Maquinas: i,
    TipoDatos: tipoDatos
  }))
  const responses = await Promise.all(requests)
  return [].concat(...responses)
}

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const getLocation = async () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => resolve(position),
        error => reject(error),
        {
          enableHighAccuracy: true,
          timeout: 10000,
        }
      )
    })
  } else {
    return null
  }
}

export const getSettings = () => {
  const settings = JSON.parse(localStorage.getItem('settings'))
  return settings || DefaultSettings
}

export const rotatePoints = (points, angle, pivot) => {
  console.log(points, angle, pivot)
  let angleRad = angle * Math.PI / 180;
  let sinAngle = Math.sin(angleRad);
  let cosAngle = Math.cos(angleRad);
  const rotatePoint = (point) => {
      let x = point[0] - pivot[0];
      let y = point[1] - pivot[1];
      let rotatedX = x * cosAngle - y * sinAngle;
      let rotatedY = x * sinAngle + y * cosAngle;
      return [rotatedX + pivot[0], rotatedY + pivot[1]];
  }
  return points.map(rotatePoint);
}

export const generateMarkerIcon = (angle) => {
  const points = [[10,0], [0,20], [10,15], [20,20]]
  const rotatedPoints = rotatePoints(points, angle, [10,10])
  console.log(rotatedPoints)
  return `<svg height="20" width="20" xmlns="http://www.w3.org/2000/svg">
    <polygon points="${rotatedPoints.map(i => i[0]+','+i[1]).join(' ')}" />
  </svg>`
}

export const CSVToArray = (data, delimiter = ',', floatProperties = []) => {
  const fnl = data.indexOf('\n');
  const titles = data.slice(0, fnl).split(delimiter).map(i => i.replace(/\"/gi, ''));
  if (fnl + 1 === data.length) {
    return [];
  }
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter).map(i => i.replace(/\"/gi, ''))
      return titles.reduce(
        (obj, title, index) => {
          return { ...obj, [title]: values[index] };
        },
        {}
      );
    });
};

export const colorClasificacion = {
  Productivo: '#80b918',
  Logistico: '#3d348b',
  Mantenimiento: '#bf0603',
  Otros: '#00a6fb',
  Maniobra: '#ff9505',
};


export const colorOperacion = (operacion) => {
  switch (operacion) {
    case 'Productivo':
      return '#80b918';
    case 'Logístico':
      return '#3d348b';
    case 'Mantenimiento':
      return '#bf0603';
    case 'Otros':
      return '#00a6fb';
    case 'Maniobra':
      return '#ff9505';
    default:
      return 'black';
  }
};
