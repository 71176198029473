import React from 'react'
import {
  IconButton,
  makeStyles
} from '@material-ui/core'
import { DataGrid } from 'devextreme-react'
import { Column, SearchPanel } from 'devextreme-react/data-grid'
import { Check, HourglassEmpty } from '@material-ui/icons'
import { green, blue } from '@material-ui/core/colors'
import { Delete } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  success: {
    fontSize: 16,
    color: green[500]
  },
  waiting: {
    fontSize: 16,
    color: blue[500]
  },
  gios: {
    listStyleType: 'none',
    margin: 0,
    padding: 0
  }
}))

const TablaArchivosTelemetria = ({ items=[], onDelete, onUpdate }) => {
  const classes = useStyles()
  const CellGIOs = (cellData) => {
    return <>
      <ul className={classes.gios}>
        {
          cellData.data.ArchivosMaquinaria.map((i, idx) => <li key={idx}>
            {i.Codigo} {i.Recibido ? <Check className={classes.success} /> : <HourglassEmpty className={classes.waiting} />}
          </li>)
        }
      </ul>
    </>
  }
  const CellOpciones = (cellData) => {
    return <>
      <IconButton onClick={() => onDelete(cellData.data.IdArchivo)}>
        <Delete />
      </IconButton>
    </>
  }
  return <DataGrid
    dataSource={items}
    showBorders
    showColumnLines
    rowAlternationEnabled
    alDataGridlowColumnResizing
  >
    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Buscar" />
    <Column caption="Nombre" dataField="Nombre" />
    <Column caption="Zafra" dataField="Zafra" />
    <Column caption="Finca" dataField="NombreFinca" />
    <Column caption="Tipo" dataField="TipoArchivo" />
    <Column caption="Recibido" cellRender={CellGIOs} />
    <Column caption="Fecha" dataField="Fecha" />
    <Column caption="Opciones" cellRender={CellOpciones} />
  </DataGrid>
}

export default TablaArchivosTelemetria