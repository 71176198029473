import { action, thunk } from 'easy-peasy'
import http from '../Utils/http'

export default {
  tiposMaquinaria: [],
  areasAgricola: [],
  clasificaciones: [],
  frentes: [],
  setTiposMaquinaria: action((state, payload) => {
    state.tiposMaquinaria = payload
  }),
  setAreasAgricola: action((state, payload) => {
    state.areasAgricola = payload
  }),
  setClasificaciones: action((state, payload) => {
    state.clasificaciones = payload
  }),
  setFrentes: action((state, payload) => {
    state.frentes = payload
  }),
  getTiposMaquinaria: thunk(async (actions, payload) => {
    try {
      let url = '/maestros/tipo_maquinaria'
      if (payload) {
        const {filtro} = payload
        if (filtro === 'otros') {
          url += '?EsOtro=True'
        }
        if (filtro === 'principal') {
          url += '?EsOtro=False'
        }
      }
      const body = await http.get(url)
      actions.setTiposMaquinaria(body)
    } catch (error) {
      throw error
    }
  }),
  getAreasAgricola: thunk(async (actions) => {
    try {
      const body = await http.get('/maestros/area')
      actions.setAreasAgricola(body)
    } catch (error) {
      throw error
    }
  }),
  getClasificaciones: thunk(async (actions) => {
    try {
      const body = await http.get('/maestros/clasificacion_operacion')
      actions.setClasificaciones(body)
    } catch (error) {
      throw error
    }
  }),
  getFrentes: thunk(async (actions, _, { getStoreState }) => {
    const { frentes } = getStoreState()
    if (!frentes.length) {
      try {
        const data = await http.get('/maestros/frente')
        actions.setFrentes(data)
      } catch (error) {
        throw error
      }
    }
  })
}
