import React, { useState, useEffect } from 'react'
import {
  IconButton
} from '@material-ui/core'
import { DataGrid } from 'devextreme-react'
import { Column, SearchPanel } from 'devextreme-react/data-grid'
import {Edit, Delete } from '@material-ui/icons'

const TablaGIO = ({ items=[], onDelete, onEdit }) => {
  const CellOpciones = (dataCell) => {
    return <>
      <IconButton onClick={() => onEdit(dataCell.data.IdGIO)}>
        <Edit />
      </IconButton>
      <IconButton onClick={() => onDelete(dataCell.data.IdGIO)}>
        <Delete />
      </IconButton>
    </>
  }
  return <DataGrid
    dataSource={items}
    showBorders
    showColumnLines
    rowAlternationEnabled
    allowColumnResizing
  >
    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Buscar" />
    <Column caption="Id" dataField="IdGIO" />
    <Column caption="Código" dataField="Codigo" />
    <Column caption="Codigo GIO" dataField="CodigoGIO" />
    <Column caption="Token" dataField="Token" />
    <Column caption="Opciones" cellRender={CellOpciones} />
  </DataGrid>
}

export default TablaGIO
