import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import AppBar from "@material-ui/core/AppBar";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useStoreActions } from "easy-peasy";

import Menu from './Menu';
let drawerWidth = 240;
const useStyles = makeStyles((theme) => {
  let styles = {
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerHidden: {
      [theme.breakpoints.up("md")]: {
        width: 0,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: "white",
        color: "#424242",
      },
    },
    appBarHidden: {
      width: '100%',
      marginLeft: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      }
    },
    menuButtonDesktop: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        display: "none",
      }
    },
    toolbar: {
      ...theme.mixins.toolbar,
      backgroundColor: "#202e3c",
      // backgroundImage: "url(./static/logo.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: 180,
      backgroundPosition: "center",
    },
    toolbarBody: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#2b3c4d",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1)
      },
    },
    title: {
      fontSize: '14pt',
      color: theme.palette.text.secondary,
      color: theme.palette.text.primary,
      fontWeigth: 'bold',
      textDecoration: 'none',
      [theme.breakpoints.down("md")]: {
        color: 'white',
      },
    },
    subtitle: {
      fontSize: '14pt',
      color: theme.palette.text.secondary,
      // color: theme.palette.text.primary,
      fontWeigth: 'bold',
      [theme.breakpoints.down("md")]: {
        color: 'white',
      },
    }
  };
  // if (props.style) {
  //   styles = { ...styles, ...props.style };
  // }
  return styles;
});

const Layout = ({ title, subtitle, ...props }) => {
  const { getPermisos } = useStoreActions(actions => ({
    getPermisos: actions.getPermisos
  }))
  const { container } = props;
  let classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openDesktop, setOpenDesktop] = React.useState(true);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDesktopToggle = () => {
    setOpenDesktop(!openDesktop);
  };

  const drawer = (
    <Menu />
  );

  useEffect(() => {
    getPermisos()
  },[])

  return (
    <div className={classes.root}>
      <CssBaseline />
      {(typeof props.showHeader === "undefined" || props.showHeader) && (
        <AppBar position="fixed" className={[classes.appBar, !openDesktop && classes.appBarHidden]}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDesktopToggle}
              className={classes.menuButtonDesktop}
            >
              <MenuIcon />
            </IconButton>
            <Breadcrumbs aria-label="breadcrumb">
              {(!props.isInicio) && (
                <Link to="/home" className={classes.title}>
                  Inicio
                </Link>
              )}
              {subtitle ? (
                <Link to={props.link || '/'} className={classes.title} >
                  {title}
                </Link>
              ) : (
                <Typography className={classes.title}>{props.title}</Typography>
              )}
              {subtitle && (
                <Typography className={classes.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Breadcrumbs>
          </Toolbar>
        </AppBar>
      )}

      <nav className={!openDesktop ? classes.drawerHidden : classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            anchor="left"
            open={openDesktop}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {(typeof props.showHeader === "undefined" || props.showHeader) && (
          <div className={classes.toolbarBody} />
        )}
        {props.children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

export default Layout
