import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Grid,
  LinearProgress,
  Fab,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { toast } from 'react-toastify'
import {default as axios2} from 'axios'
import http from '../../Utils/http'
import dayjs from 'dayjs'

import Layout from '../../components/Layout/layout'
import { getIdentity } from '../../Utils/auth'
import TablaArchivosTelemetria from '../../components/GIO/TablaArchivosTelemetria'
import ArchivoTelemetriaEdit from './ArchivoTelemetriaEdit'
import ArchivoTelemetriaUpdate from '../../components/GIO/ArchivoTelemetriaUpdate'

const URL_ARCHIVOS = 'https://jxsgv0fc0e.execute-api.us-east-1.amazonaws.com/v1/archivo'

const useStyles = makeStyles(theme => ({
  fabButton: {
    backgroundColor: "#69BD4B",
    color: "white"
  }
}))

const ArchivosTelemetria = () => {
  const classes = useStyles()
  const [archivos, setArchivos] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogAdd, setDialogAdd] = useState(false)
  const [dialogUpdate, setDialogUpdate] = useState(false)
  const [selected, setSelected] = useState(null)
  const getArchivos = async () => {
    try {
      setLoading(true)
      // const listGios = await getGios()
      const data = await http.get('/maestros/archivo')
      console.log(data)
      setArchivos(data.map(i => ({
        ...i,
        Fecha: dayjs(i.FechaCreo).format('DD/MM/YYYY')
      })))
      // setArchivos(body.map(i => ({
      //   ...i,
      //   ListaGIO: i.ListaGIO
      //     .map(j => listGios.find(k => k.IdGIO === j))
      //     .map(j => ({...j, Recibido: Boolean(i.Recibido.find(k => k === j.IdGIO))}))
      // })))
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const deleteArchivo= async (idArchivo) => {
    try {
      if (window.confirm('¿Estás seguro de borrrar el registro?')) {
        setLoading(true)
        await http.delete(`/maestros/archivo/${idArchivo}`,  {data:{UsuarioModifico: getIdentity()}})
        getArchivos()
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const updateArchivo = async (data) => {
    await axios2.put(`${URL_ARCHIVOS}/${selected.IdGIO}`, {
      ...data,
      ModificadoPor: getIdentity()
    })
  }
  const showUpdate = (index) => {
    setSelected({...archivos[index]})
    setDialogUpdate(true)
  }
  const refresh = () => {
    getArchivos()
    setDialogAdd(false)
    setDialogUpdate(false)
  }
  useEffect(() => {
    getArchivos()
  }, [])
  return <Layout title="Archivos de telemetría">
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{marginBottom: 10}}
    >
      <Typography variant="h6" component="p">Archivos de telemetría</Typography>
      <Fab size="small" className={classes.fabButton} onClick={() => setDialogAdd(true)}>
        <Add />
      </Fab>
    </Box>
    {
      loading && <LinearProgress />
    }
    <TablaArchivosTelemetria
      items={archivos}
      onDelete={deleteArchivo}
      onUpdate={showUpdate}
    />
    <Dialog fullWidth maxWidth="md" open={dialogAdd}>
      <ArchivoTelemetriaEdit
        title="Agregar archivo"
        onClose={() => setDialogAdd(false)}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dialogUpdate}>
      <ArchivoTelemetriaUpdate
        title="Editar archivo"
        onClose={() => setDialogUpdate(false)}
        onSubmit={updateArchivo}
        onRefresh={refresh}
        initial={selected}
      />
    </Dialog>
  </Layout>
}

export default ArchivosTelemetria