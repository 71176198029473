import React, { useState, useEffect } from 'react'
import {
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import http from '../../Utils/http'

const GIOSelect = ({
  value,
  onChange,
  error=false,
  helperText='',
  disabled=false,
  defaultValue=null,
  maquinaria=[]
}) => {
  const [items, setItems] = useState([])
  const getItems = async () => {
    const data = await http.get('/maestros/gio')
    let tmp = [...data]
    if (maquinaria.length) {
      const gios = maquinaria.map(m => m.IdGIO)
      tmp = tmp.filter(i => !gios.includes(i.IdGIO) || i.IdGIO == defaultValue)
    }
    setItems(tmp)
  }
  useEffect(() => {
    if (!items.length) {
      getItems()
    } else if (defaultValue) {
      onChange({target: {
        name: 'GIO',
        value: items.find(i => i.IdGIO === defaultValue)
      }})
    }
  }, [items, defaultValue, maquinaria])
  return <Autocomplete
  fullWidth
  options={items}
  disabled={disabled}
  getOptionLabel={option => option['Codigo']}
  renderInput={params => <TextField
    {...params}
    fullWidth
    error={error}
    label="GIO"
    margin="none"
    helperText={helperText}
  />}
  value={value}
  onChange={(ev, value) => onChange({target: {name: 'GIO', value}})}
/>
}

export default GIOSelect
