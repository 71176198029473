import React from 'react';
import { DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Navigation, Edit } from '@material-ui/icons';

const GeoBardasMapa = ({ onClose, onDrawing, onMarking }) => {
  return (
    <>
      <DialogTitle>Crear GeoBarda</DialogTitle>
      <DialogContent>
        <Button fullWidth color="primary" variant="contained" startIcon={<Edit />} onClick={onDrawing}>
          Dibujar GeoBarda
        </Button>
        <Button fullWidth color="secondary" variant="contained" startIcon={<Navigation />} onClick={onMarking}>
          Trazar con posición GPS
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </>
  );
}

export default GeoBardasMapa;
