import React from 'react'
import {
  Typography
} from '@material-ui/core'

import Layout from '../../components/Layout/layout'
import MapaUbicacionTemporal from '../../components/GIO/MapaUbicacionTemporal'
import { isMobile } from '../../Utils/functions'

const MapaTemporal = () => {
  return <Layout title="Mapa de ubicación en tiempo real">
    {
      !isMobile() && <Typography variant="h6" component="p">Ubicación en tiempo real</Typography>
    }
    <MapaUbicacionTemporal />
  </Layout>
}
export default MapaTemporal
