import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import { toast } from 'react-toastify'
import SelectFrente from '../../Selects/SelectFrente'
import LoadingButton from '../../Utils/LoadingButton'

const GuardarGeoCerca = ({ onClose, onSave }) => {
  const [estado, setEstado] = useState({
    descripcion: '',
    frente: null,
    frenteDividido: false,
    grupo: null
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const validate = () => {
    const errors = {}
    if (estado.frenteDividido && !estado.grupo) {
      errors.grupo = 'Campo requerido'
    }
    if (!estado.frente) {
      errors.frente = 'Campo requerido'
    }
    setErrors(errors)
    return Object.keys(errors).length === 0
  }
  const submitGeoCerca = async () => {
    if (!validate()) return
    setLoading(true)
    try {
      await onSave(estado)
      onClose()
    } catch (error) {
      console.error(error)
      toast.error('Error al guardar la geocerca')
    } finally {
      setLoading(false)
    }
  }
  return <>
    <DialogTitle>Guardar geocerca</DialogTitle>
    <DialogContent>
      <SelectFrente
        value={estado.frente}
        onChange={e => setEstado({ ...estado, frente: e.target.value })}
        error={Boolean(errors.frente)}
        helperText={Boolean(errors.frente) && errors.frente}
      />
      <FormControlLabel
        label='Frente dividido'
        control={
          <Switch
            checked={estado.frenteDividido}
            onChange={e => setEstado({ ...estado, frenteDividido: e.target.checked })}
          />
        }
      />
      {
        estado.frenteDividido &&  <FormControl
          fullWidth
          error={Boolean(errors.grupo)}
        >
          <InputLabel id='grupo'>Grupo</InputLabel>
          <Select
            labelId='grupo'
            value={estado.grupo}
            onChange={e => setEstado({ ...estado, grupo: e.target.value })}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
          <FormHelperText>{Boolean(errors.grupo) && errors.grupo}</FormHelperText>
        </FormControl>
      }
      <TextField
        multiline
        rows={3}
        label='Descripción'
        value={estado.descripcion}
        onChange={e => setEstado({ ...estado, descripcion: e.target.value })}
        fullWidth
        error={Boolean(errors.descripcion)}
        helperText={Boolean(errors.descripcion) && errors.descripcion}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Cancelar
      </Button>
      <LoadingButton
        onClick={submitGeoCerca}
        color='primary'
        variant='contained'
        loading={loading}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default GuardarGeoCerca
