import React, { useState, useEffect } from 'react'
import {
  Box,
  Fab,
  Grid,
  Dialog,
  IconButton,
  Typography,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Add, Edit, Delete } from '@material-ui/icons'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { toast } from 'react-toastify'
import { DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import { getIdentity } from '../../Utils/auth'
import http from '../../Utils/http'
import Layout from '../../components/Layout/layout'
import TipoMaquinariaEdit from '../../components/Admin/TipoMaquinariaEdit'

const useStyles = makeStyles(theme => ({
  fab: {
    background: green[500],
    color: '#FFF'
  },
  title: {
    padding: 5
  }
}))

const MantenimientoTiposMaquinaria = () => {
  const classes = useStyles()
  const { tiposMaquinaria } = useStoreState(state => ({
    tiposMaquinaria: state.tiposMaquinaria
  }))
  const { getTiposMaquinaria } = useStoreActions(actions => ({
    getTiposMaquinaria: actions.getTiposMaquinaria
  }))
  const [loading, setLoading] = useState(false)
  const [dgCrear, setDgCrear] = useState(false)
  const [dgEditar, setDgEditar] = useState(false)
  const [selected, setSelected] = useState(null)
  const getItems = async () => {
    try {
      setLoading(true)
      await getTiposMaquinaria()
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const insertItem = async (form) => {
    try {
      const body = {
        ...form,
        UsuarioCreo: getIdentity()
      }
      await http.post('/maestros/tipo_maquinaria', body)
    } catch (error) {
      throw error
    }
  }
  const updateItem = async (form) => {
    try {
      const body = {
        ...form,
        UsuarioModifico: getIdentity()
      }
      await http.put(`/maestros/tipo_maquinaria/${selected.IdTipoMaquinaria}`, body)
    } catch (error) {
      throw error
    }
  }
  const refresh = () => {
    getItems()
    setDgCrear(false)
    setDgEditar(false)
  }
  const editItem = (cellData) => {
    const { data } = cellData
    setSelected(data)
    setDgEditar(true)
  }
  const deleteItem = async (cellData) => {
    if (window.confirm('¿Estas seguro de borrar el registro?')) {
      try {
        const { data } = cellData
        await http.delete(`/maestros/tipo_maquinaria/${data.IdTipoMaquinaria}`,
          { data: {UsuarioModifico: getIdentity()} })
        getItems()
      } catch (error) {
        toast.error('Ha ocurrido un error')
      }
    }
  }
  useEffect(() => {
    getItems()
  }, [])
  const CellOpciones = (cellData) => (
    <div>
      <IconButton onClick={() => editItem(cellData)} size="small">
        <Edit />
      </IconButton>
      <IconButton onClick={() => deleteItem(cellData)} size="small">
        <Delete />
      </IconButton>
    </div>
  )
  return <Layout title="Tipos de maquinaria">
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
      <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.title}
        >
          <Typography variant="h5">Tipos de maquinaria</Typography>
          <Fab
            size="small"
            color="inherit"
            className={classes.fab}
            onClick={() => setDgCrear(true)}
          >
            <Add />
          </Fab>
        </Box>
        {
          loading && <LinearProgress />
        }
        <DataGrid id="gridTiposMaquinaria"
          dataSource={tiposMaquinaria}
          showBorders
          showColumnLines
          rowAlternationEnabled
        >
          <Column caption="Id" dataField="IdTipoMaquinaria" />
          <Column caption="Tipo" dataField="Nombre" />
          <Column caption="Opciones" cellRender={CellOpciones} />
        </DataGrid>
      </Grid>
    </Grid>
    <Dialog open={dgCrear} maxWidth="sm" fullWidth>
      <TipoMaquinariaEdit
        title="Crear tipo de maquinaria"
        onClose={() => setDgCrear(false)}
        onSubmit={insertItem}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog open={dgEditar} maxWidth="sm" fullWidth>
      <TipoMaquinariaEdit
        title="Editar tipo de maquinaria"
        onClose={() => setDgEditar(false)}
        onSubmit={updateItem}
        onRefresh={refresh}
        values={selected}
      />
    </Dialog>
  </Layout>
}

export default MantenimientoTiposMaquinaria
