import React, { useState, useEffect } from 'react'
import {
  Dialog,
  Box,
  Typography,
  Fab,
  LinearProgress
} from '@material-ui/core'
import http from '../../Utils/http'
import { toast } from 'react-toastify'
import Layout from '../../components/Layout/layout'
import { green } from '@material-ui/core/colors'
import { Add } from '@material-ui/icons'
import MaquinariaTabla from '../../components/Maestros/MaquinariaTabla'
import { getIdentity } from '../../Utils/auth'
import MaquinariaEdit from '../../components/Maestros/MaquinariaEdit'
import VariablesShow from '../../components/Alarmas/VariablesShow'
import VariablesEdit from '../../components/Alarmas/VariablesEdit'

const MantenimientoMaquinaria = () => {
  const [dialogCreate, setDialogCreate] = useState(false)
  const [dialogEdit, setDialogEdit] = useState(false)
  const [items, setItems] = useState([])
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dgShow, setDgShow] = useState(false)
  const [dgVariables, setDgVariables] = useState(false)
  const getItems = async () => {
    try {
      setLoading(true)
      const data = await http.get('/maestros/maquinaria')
      setItems(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const showEdit = (IdMaquinaria) => {
    const item = items.find(i => i.IdMaquinaria === IdMaquinaria)
    setSelected(item)
    setDialogEdit(true)
  }
  const deleteItem = async (IdMaquinaria) => {
    if (window.confirm('¿Estas seguro de eliminar el registro?')) {
      try {
        const id  = items.find(i => i.IdMaquinaria == IdMaquinaria).IdMaquinaria
        await http.delete(`/maestros/maquinaria/${id}`, {data: {UsuarioModifico: getIdentity()}})
        getItems()
      } catch (error) {
        toast.error('Ha ocurrido un error')
      }
    }
  }
  const refresh = () => {
    getItems()
    setDialogCreate(false)
    setDialogEdit(false)
    setDgVariables(false)
  }
  const insertItem = async (form) => {
    await http.post('/maestros/maquinaria', {
      ...form,
      UsuarioCreo: getIdentity()
    })
  }
  const updateItem = async (form) => {
    await http.put(`/maestros/maquinaria/${selected.IdMaquinaria}`, {
      ...form,
      UsuarioModifico: getIdentity()
    })
  }
  const showVariables = (IdMaquinaria) => {
    setSelected({...items.find(i => i.IdMaquinaria === IdMaquinaria)})
    setDgShow(true)
  }
  const showEditVariables = (IdMaquinaria) => {
    setSelected({...items.find(i => i.IdMaquinaria === IdMaquinaria)})
    setDgVariables(true)
  }
  useEffect(() => {
    getItems()
  }, [])
  return <Layout title="Maquinaria">
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography component="p" variant="h6">Mantenimiento de maquinaria</Typography>
      <Fab
        color="secondary"
        size="small"
        style={{background: green[500]}}
        onClick={() => setDialogCreate(true)}
      >
        <Add />
      </Fab>
    </Box>
    {
      loading && <LinearProgress />
    }
    <MaquinariaTabla
      items={items}
      onDelete={deleteItem}
      onEdit={showEdit}
      onShowVariables={showVariables}
      onEditVariables={showEditVariables}
    />
    <Dialog fullWidth maxWidth="md" open={dialogCreate}>
      <MaquinariaEdit
        title="Crear maquinaria"
        onClose={() => setDialogCreate(false)}
        onSubmit={insertItem}
        onRefresh={refresh}
        items={items}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dialogEdit}>
      <MaquinariaEdit
        title="Editar maquinaria"
        onClose={() => setDialogEdit(false)}
        onSubmit={updateItem}
        values={selected}
        onRefresh={refresh}
        items={items}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dgShow}>
      <VariablesShow
        onClose={() => setDgShow(false)}
        values={selected}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dgVariables}>
      <VariablesEdit
        maquinaria={selected}
        onClose={() => setDgVariables(false)}
        onRefresh={refresh}
      />
    </Dialog>
  </Layout>
}

export default MantenimientoMaquinaria
