import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/Layout/layout';
import { colorOperacion, generateMarkerIcon } from '../../Utils/functions';
import http from '../../Utils/http';
import { getIdentity } from '../../Utils/auth';
import { toast } from 'react-toastify';
import { Box, Drawer, Fab } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { FilterList as IconFilter } from '@material-ui/icons';
import FiltrosForm from '../../components/GIO/mapa-sendero/FiltrosForm';
import useInterval from '../../hooks/useInterval';
import { CSVToArray, getShapes, colorClasificacion } from '../../Utils/functions';
import axios from 'axios';
import { useStoreState, useStoreActions } from 'easy-peasy';

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '85vh',
    width: '100%',
    position: 'relative'
  },
  drawer: {
    width: 280,
    padding: 16
  },
  btnFiltros: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    background: green[500],
    color: '#FFF',
    zIndex: 500
  },
  leyendas: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1000,
    background: 'rgba(255,255,255,0.8);',
    padding: theme.spacing(1),
  }
}))

const LAT = process.env.REACT_APP_MAPA_LAT
const LON = process.env.REACT_APP_MAPA_LON

const MapaSendero = () => {
  const zafraActual = useStoreState(state => state.zafraActual)
  const getZafraActual = useStoreActions(actions => actions.getZafraActual)
  const classes = useStyles();
  const mapa = useRef(null)
  const shape = useRef(null)
  const baseMap = useRef(null)
  const pointsLayer = useRef(null)
  const [sendero, setSendero] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);

  const showPunto = () => {
    if (shape.current != null) {
      shape.current.remove()
    }
    shape.current = window.L.marker([LAT, LON], {
      icon: window.L.divIcon({
        className: 'sendero-move',
        html: `<div>
          ${generateMarkerIcon(30)}
        </div>`,
      })
    }).addTo(mapa.current)
  }

  const crearDataset = async (values) => {
    try {
      const data = await http.post('/maestros/dataset', {
        FechaInicia: values.FechaInicia.replace('T', ' ') + ':00',
        FechaTermina: values.FechaTermina.replace('T', ' ') + ':00',
        DBNombre: 'gio-dc',
        DBTabla: 'historico',
        Filtros: {
          maquinaria: values['Maquinaria'].map(m => m.Codigo),
        },
        Columnas: ['latitud', 'longitud', 'clasificacion_operacion'],
        UsuarioCreo: getIdentity(),
        ConsultarHora: true,
      })
      setDataset(data)
    } catch (error) {
      console.error(error)
    }
  }

  const showPuntos = (puntos=[]) => {
    if (pointsLayer.current != null) {
      pointsLayer.current.remove()
    }
    const markers = []
    for (const p of puntos) {
      if (Boolean(p.latitud) && Boolean(p.longitud)) {
        markers.push(window.L.circleMarker([p.latitud, p.longitud], {
          render: window.L.canvas({ padding: 0.5 }),
          color: colorOperacion(p.clasificacion_operacion),
          radius: 1,
          fillOpacity: 1
        }).addTo(mapa.current))
      }
    }
    pointsLayer.current = window.L.layerGroup(markers)
    pointsLayer.current.addTo(mapa.current)
    setLoading(false)
  }

  const getPresignedUrl = async (id) => {
    try {
      const presigned_url = await http.get(`/maestros/dataset/download?IdQuery=${id}`);
      setUrl(presigned_url);
    } catch (error) {
      toast.error('Error al obtener los datos')
    }
  }
  const getDatasetStatus = async (id) => {
    try {
      const status = await http.get(`/maestros/dataset/${id}`);
      setDataset(status)
    } catch (error) {
      toast.error('Error al obtener los datos')
    }
  }
  const getSendero = async (values) => {
    try {
      setLoading(true)
      await crearDataset(values)
    } catch (error) {
      toast.error('Error al obtener los datos')
    }
  }
  const getPuntos = async () => {
    try {
      const { data } = await axios.get(url, { responseType: 'text' });
      const puntos = CSVToArray(data, ',').map(i => ({...i, latitud: parseFloat(i.latitud), longitud: parseFloat(i.longitud)}))
      showPuntos(puntos)
    } catch (error) {
      console.error(error)
      toast.error('Error al obtener los datos')
    }
  }
  const getShapeZafra = async () => {
    try {
      if (shape.current == null) {
        const GeoJSON = await getShapes(zafraActual.Codigo)
        shape.current = window.L.geoJSON(GeoJSON, {
            style: (feature) => {
            return {
              color: '#faa307',
              fillOpacity: 0,
              weight: 1
            }
          }
        })
        shape.current.addTo(mapa.current)
      }
    } catch (error) {
      toast.error('No se pudo obtener el shape de la zafra')
    }
  }

  useEffect(() => {
    if (mapa.current === null) {
      mapa.current = window.L.map('map-location', {
        preferCanvas: true,
        zoomSnap: 1,
        attributionControl: false,
      }).setView([LAT, LON], 11)
      baseMap.current = window.L.tileLayer(
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          attribution: 'Tiles &copy; Esri &mdash',
          maxZoom: 18
        })
      baseMap.current.addTo(mapa.current)
      // mapa.current.on('zoomend', () => {
      //   const zoomLevel = mapa.current.getZoom()
      //   if (pointsLayer.current != null) {
      //     if (zoomLevel >= 15 && !mapa.current.hasLayer(pointsLayer.current)) {
      //       pointsLayer.current.addTo(mapa.current)
      //     }
      //     if (zoomLevel < 15 && mapa.current.hasLayer(pointsLayer.current)) {
      //       pointsLayer.current.remove()
      //     }
      //   }
      // })
    }
  }, [])
  // useEffect(() => {
  //   if (mapa.current != null) {
  //     showPunto()
  //   }
  // }, [mapa])
  useEffect(() => {
    if (!zafraActual) {
      getZafraActual()
    } else {
      getShapeZafra()
    }
  }, [zafraActual])
  useEffect(() => {
    if (url != null && loading) {
      (async () => {
        await getPuntos()
        setUrl(null)
      })();
    }
  }, [url, loading])
  useInterval(() => {
    if (dataset != null && dataset.Estado == 'Fallo') {
      return
    }
    if (dataset != null && dataset.Estado != 'Listo') {
      getDatasetStatus(dataset.IdDataset)
    } else if (dataset != null && dataset.Estado == 'Listo' && loading) {
      getPresignedUrl(dataset.IdQuery)
    }
  }, 800)

  return (
    <Layout title="Mapa de sendero">
      <div id="map-location" className={classes.mapContainer}>
        <Fab size="large" className={classes.btnFiltros} onClick={() => setOpen(true)}>
          <IconFilter />
        </Fab>
        <Box className={classes.leyendas}>
        {
          Object.keys(colorClasificacion).map((key, index) =>  <Box display="flex" key={key} alignItems="center">
            <Box width={10} height={10} borderRadius="50%" bgcolor={colorClasificacion[key]}/>
            <Box ml={1}>{key}</Box>
          </Box>)
        }
        </Box>
      </div>
      <Drawer
        classes={{
          paper: classes.drawer,
        }}
        variant="temporary"
        open={open}
        anchor="right"
      >
        <FiltrosForm
          onSubmit={getSendero}
          loading={loading}
          onClose={() => setOpen(false)}
        />
      </Drawer>
    </Layout>
  );
};

export default MapaSendero;
